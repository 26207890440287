import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const Carousel = ({ title, articles }) => {
  const slider = React.useRef();

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: "unslick"
      }
    ]
  }

  const renderCard = (article) => (
    <li key={article.title} className="column is-half">
      <a className="card" href={article.slug_path}>
      <div className="card-image">
        <figure className="image">
          <img src={article.thumbnail || 'artikel-thumb.jpg'} alt={article.title} />
        </figure>
        { article.is_exaktueel && (
          <div className="card-label">
            <h5>Exaktueel</h5>
          </div>
        )}
      </div>
      <div className="card-content">
        <h4>{article.title}</h4>
        <p dangerouslySetInnerHTML={{__html : article.summary }} />
      </div>
      </a>
  </li>
  );

  useEffect(() => {
    if(window.initTruncateTitles) {
      setTimeout(() => {
        window.initTruncateTitles();
       }, 0);
    }
  }, [])

  return (
    <>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8">
            <h2 className="mb-4">{title}</h2>
            {articles.length >= 2 ? (
              <Slider {...settings} ref={slider}>
                {articles.map((article) => renderCard(article))}
              </Slider>
            )
            : articles.map(article => renderCard(article))}
          </div>
        </div>
        {articles.length > 2 && (
            <div className="arrows-container">
              <button className="arrow arrow-prev" onClick={() => slider.current.slickPrev()} />
              <button className="arrow arrow-next" onClick={() => slider.current.slickNext()} />
          </div>
        )}
      </div>
    </>
  );
}

export default Carousel;